import React from 'react'
import video from '../../assets/background.mp4'
import profile from '../../assets/gallery02.jpeg'
import facebook from '../../assets/facebook.png'
import instagram from '../../assets/instagram.png'
import gmail from '../../assets/gmail.png'

import './Contact.css'



const Contact = () => {
    return (
      <section className="contact app__wrapper section__padding app__bg flex__center section__padding">
      <div className="content">
          <h2>Contactează-mă</h2>
          <p>Ai nevoie de ore de canto sau de un solist de muzică populară la un eveniment? <br/>Vrei sa faci cadou o pictură sau vrei să îți compun câteva versuri ?</p>
          <p>Completează formularul de mai jos și vom stabili împreună toate detaliile</p>
      </div>
      <div className="container">
          <div className="contactInfo">
              <div className="box">
                  <div className="icon"><i className="fa fa-map-marker" aria-hidden="true"></i></div>
                  <div className="text">
                      <h3>Address</h3>
                      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum commodi reiciendis similique.</p> 
                  </div> 
              </div>
              <div className="box">
                  <div className="icon"><i className="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <div className="text">
                      <h3>Phone</h3>
                      <p>123 123 12345</p> 
                  </div>
              </div>
              <div className="box">
                  <div className="icon"><i className="fa fa-envelope" aria-hidden="true"></i></div>
                  <div className="text">
                      <h3>Email</h3>
                      <p>andreipetrulazar@gmail.com</p> 
                  </div>
              </div>
              <div className='social-media'>
              <div className="box">
              <a href="https://www.facebook.com/Paula.Andrei.folclor" target='_blank'>  <div className="icon"><i class="fa-brands fa-facebook"></i> </div></a>
                  <div className="text">
                
                     
                  </div>
              </div>
              
              <div className="box">
                <a href="https://www.instagram.com/andrei_paula/" target="_blank"> <div className="icon"><i class="fa-brands fa-instagram" aria-hidden="true"></i></div> </a>
                  <div className="text">
                 
                     
                  </div>
              </div>
              <div className="box">
                 <a href="https://www.youtube.com/channel/UC-7DnzMhiNvA73BrDGTXzBw" target='_blank'> <div className="icon"><i className="fa-brands fa-youtube" aria-hidden="true"></i></div> </a>
                  <div className="text">
                   
                     
                  </div>
              </div>
              </div>
          </div>
          <div className="contactForm">
              <form >
                  <h2>Formular de Contact</h2>
                 
                  <div className="inputBox">
                      <input type="text" name="" required='required' />
                      <span>Nume și Prenume</span>
                  </div>
                  <div className="inputBox">
                      <input type="text" name="" required='required' />
                      <span>Email</span>
                  </div>

                  <div className="inputBox">
                      <input type="text" name="" required='required' placeholder='Rezervare eveniment artistic / Ore de canto / Pictura cadou / Versuri'/>
                      <span>Alege o categorie</span>
                     
                  </div>
                 
                  <div className="inputBox">
                      <textarea required="required"></textarea>
                      <span>Scrie mesajul tău... </span>
                  </div>
                  <div className="btn">
                      <input type="submit" value="Send" />
                  </div>
              </form>
          </div>
      </div>
  </section> 
    )

};

export default Contact;